<template>
  <v-container class="SelfieIdentityCard">
      <div align="center" >
        <h4> {{$t('SelfiewithyourStudentID')}}</h4> 
        <p>{{$t('Makesureyourfaceisintheovalarea')}}</p>
          <v-img 
          width="80%"
          height="80%"  
          :src="require('../../assets/kyc/selfiecardexample.svg')"></v-img>
          <br>
        
        <div 
          align="center" class="footer">
          <v-btn 
          rounded
          block
          color="primary"
          @click="clickSelfieIdentityCard()">
          {{$t('Next')}} 
        </v-btn>

        </div>
      </div>
        
    </v-container>
</template>

<script>

  export default {
    name:"SelfieIdentityCard",
    components: { },
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,

        notifdontActivePromotion:true,
        notifAvailableUnderAged:true,
        notifAvailableStudent:true,
        notifAvailableTransit:true,
        pinActiveUnderAged: "",
        pinActiveStudent: "",
        pinActiveTransit: "",
        typePromo:[],
        dataTypePromo:[
          {promo_id: "57", promo_name: "Under-Aged Student Promotion"},
          {promo_id: "58", promo_name: "Student Promotion"},
          {promo_id: "59", promo_name: "Transit Promotion Fare"}
        ],
      }
    },
    methods:{ 
      clickSelfieIdentityCard(){
        this.$router.push({
          name: 'CameraOnSelfieIdentityCard',
        })
      },
      
      
    },
    mounted(){
      
    }
  }
</script>


<style lang="scss" scoped>
.background-image {
  position: absolute;
  width: 351px;
  height: 436px;
  left: 32px;
  top: 193px;
}
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}

.footer {
  position: absolute;
  left: 8%;
  bottom: 0;
  width: 90%;
}
</style>